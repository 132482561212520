export interface Entity {
  name: string;
  value: string;
  tokenPosition: number;
  annotationColor?: string;
}

export interface TextPrediction {
  class: string;
  entities: Entity[];
  tokens: string[];
  // current workaround for backend to use objects with unknown entries
  multi_classes: Record<string, number>;
  single_classes: Record<string, number>;
}

export interface LLMResponseJson {
  Main_Class: string,
  Additional_Classes: Array<{
      Klasse: string,
      Relevanz: number,
      Confidence: number
  }>,
  Entities: Array<{
      Type: string,
      Content: string
  }>
}

export enum TextStatus {
  Start = "start",
  Loading = "loading",
  Done = "done",
  Error = "error",
}
