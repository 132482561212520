
import { Vue, Options } from "vue-class-component";

@Options({
  name: "bottom-bar",
})
export default class BottomBar extends Vue {
  get currentYear(): number {
    return new Date().getFullYear();
  }
}
