
import { Options, Vue } from "vue-class-component";
import ResultBox from "@/components/ResultBox.vue";
import DropSelect from "@/components/DropSelect.vue";
import SimpleDialog from "@/components/SimpleDialog.vue";
import {  BACKEND_URL,BACKGROUND_COLORS } from "@/constants";
import { TextPrediction, TextStatus, LLMResponseJson} from "@/types";
import jsonData from "./example_mails/exampleMails.json";



@Options({
  name: "control-center",
  components: {
    ResultBox,
    DropSelect,
    SimpleDialog
  },
})
export default class ControlCenter extends Vue {
  result: TextPrediction | null = null;
  status = TextStatus.Start;
  TextStatus = TextStatus; // needed to use enum in html template
  showFeedbackMsg = false;
  class_dialog = false;
  entity_dialog = false;
  inputText = "";
  renderedInput = "";
  class_descriptions: Record<string, string> = {
    "Backoffice": "Diese Klasse behandelt administrative Anfragen und Anliegen. Dazu gehören beispielsweise Benachrichtigungen über Änderungen in Verträgen oder persönlichen Umständen, wie der Tod eines Kunden. Oft handelt es sich um sensible Themen, die mit besonderer Sorgfalt und Verständnis behandelt werden müssen.",
    "Bankdatenänderung": "Diese Klasse befasst sich mit Anfragen zur Änderung von Bankdaten. Die Kunden senden Informationen zu ihrer aktuellen Bankverbindung und bitten um Aktualisierung in den Datensätzen des Energieversorgers. Wichtig ist hier, dass solche sensiblen Informationen sicher und vertraulich behandelt werden müssen.",
    "Kundendatenänderung": "Diese Klasse betrifft Anfragen zur Änderung der Kundendaten. Dazu gehören beispielsweise Änderungen des Namens nach Heirat oder Fehlern bei der Erstanmeldung, oder auch Änderungen im monatlichen Abschlag.",
    "Mahnungen": "Diese Klasse befasst sich mit Anfragen im Zusammenhang mit Zahlungsanliegen. Hierunter fallen Ratenzahlungen, Rückzahlungen oder Anfragen zur Vermeidung doppelter Abbuchungen.",
    "Reklamation": "In dieser Klasse geht es um Beschwerden und Anfragen zur Korrektur von Fehlern. Das können beispielsweise ungenaue oder falsche Rechnungen, fehlende Schlussrechnungen oder Anfragen nach Kopien von Rechnungen sein.",
    "Umzugsmeldung": "Diese Klasse befasst sich mit Umzugsmeldungen. Kunden senden Informationen über den Wechsel ihrer Adresse oder melden einen Leerstand ihrer bisherigen Wohnung. Diese Informationen sind wichtig, um den Energieverbrauch korrekt zuzuordnen und abzurechnen.",
    "Widerruf": "Diese Klasse beinhaltet Anfragen zur Stornierung von Verträgen. Kunden, die ihre Verträge widerrufen möchten, senden in der Regel detaillierte Informationen zu dem Vertrag, den sie widerrufen möchten, und bitten um eine Bestätigung des Widerrufs.",
    "Zählerstand": "In dieser Klasse geht es um Anfragen zum Energieverbrauch und Zählerständen. Kunden melden ihre aktuellen Zählerstände oder korrigieren falsch gemeldete Werte. Diese Informationen sind entscheidend für die genaue Abrechnung des Energieverbrauchs.",
    "Sonstiges": "Diese Klasse beinhaltet absolut alle E-Mails welche nicht sicher zu einer der anderen oben genannten Kategorien/Klassen eingeordnet werden können."
  };
  entities_descriptions = {
    "Name": "Bspw. \"Max Mustermann\"",
    "Vorname": "Bspw. \"Max\"",
    "Nachname": "Bspw. \"Mustermann\"",
    "Adresse": "Bspw. \"Platz d. Republik 1, 10557 \"",
    "Straße und Nummer": "Bspw. \"Platz d. Republik 1\"",
    "Postleitzahl": "Bspw. \"10557\"",
    "Stadt": "Bspw. \"Berlin\"",
    "Vertragsnummer": "Bspw. \"xxx10123456789\"",
    "Kontonummer": "Bspw. \"xxx1234567\"",
    "Zählernummer": "Bspw. \"1ABCD0123456789\"",
    "Bankverbindung(IBAN)": "Bspw. \"DE1001100112345678132499\"",
    "Bankleitzahl(BIC)": "Bspw. \"ABCDEFGXXX\"",
    "Kontoinhaber": "Bspw. \"Max Mustermann\"",
    "Zählerstand": "Bspw. \"31415,9 kWh\"",
    "Rechnungsdatum": "Bspw. \"01.02.1998\"",
    "Umzugsdatum": "Bspw. \"01.02.1998\"",
    // "Genanntes Einzugsdatum": "Bspw. \"01.02.1998\" - Dieses muss explizit genannt werden",
    // "Genanntes Auszugsdatum": "Bspw. \"01.02.1998\" - Dieses muss explizit genannt werden",
    // "Vermutetes Einzugsdatum": "Bspw. \"01.02.1998\" - Dieses kann basierend auf dem Auszugsdatum geschätzt werden",
    // "Vermutetes Auszugsdatum": "Bspw. \"01.02.1998\" - Dieses kann basierend auf dem Einzugsdatum geschätzt werden",
    "Zählerstandsmeldedatum": "Bspw. \"01.02.1998\"",
}
  // keeps track which colors where for annotations
  colorIdx = 0;

  handleSave(newDescriptions: Record<string, string>) {
  this.class_descriptions = newDescriptions;
  // Optionally, trigger an update to the server or store here
}

  /**
   * Reset everything to type in a new text to analyze
   */
  resetInput(): void {
    this.inputText = "";
    this.renderedInput = "";
    this.status = TextStatus.Start;
    this.colorIdx = 0;
  }

  setExampleText(selectStatusObject: { transferStatus: string; exNumber: number }): void {
    //console.log("Parent Component:", selectStatusObject);
    // console.log("transferStatus: ", selectStatusObject.transferStatus);
    // console.log("exNumber: ", selectStatusObject.exNumber);

    // reset of input field in case of double clicks
    this.renderedInput = "";
    this.status = TextStatus.Start;
    this.colorIdx = 0;

    // this.inputText = jsonData[this.selectStatus][this.number]

    if (selectStatusObject.transferStatus == "backoffice")
      this.inputText = jsonData["backoffice"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "bankdaten")
      this.inputText = jsonData["bankdaten"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "kundendaten")
      this.inputText = jsonData["kundendaten"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "mahnung")
      this.inputText = jsonData["mahnung"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "reklamation")
      this.inputText = jsonData["reklamation"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "umzug")
      this.inputText = jsonData["umzug"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "widerruf")
      this.inputText = jsonData["widerruf"][selectStatusObject.exNumber];
    if (selectStatusObject.transferStatus == "zaehlerstand")
      this.inputText = jsonData["zaehlerstand"][selectStatusObject.exNumber];
  }


  async sendText(): Promise<void> {
    // send status to loading
    this.status = TextStatus.Loading;

    const system_prompt = "Du bist ACEBot (Automated Content Extraction) eine KI für die automatisierte Aufbereitung und Bearbeitung von Kundenservice Anfragen im Energieumfeld. Als solcher Antwortest du auf jedewede Anfrage auschließlich in einem vordefiniertem JSON format und auf sonst keine andere Art und Weise. \n\nDeine Hauptaufgabe ist das Kategorisieren von E-Mails aus dem Kundenservice. \nZusätzlich unterstützt du aber auch bei der Extraktion von relevanten Entityen.\n\n";
    const classes_prompt = "Die möglichen Klassen die klassifiziert werden können sind im folgenden gelistet und beschrieben:\n"
    const descriptionsString = Object.entries(this.class_descriptions)
                                .map(([key, value]) => `Klasse "${key}": ${value}`)
                                .join('\n')
    const classes_prompt_suffix = "\nWichtig: Es gibt KEINE anderen Klassen die Möglich sind, auschließlich die zuvor genannten Klassen können Anliegen sein!\nAllerdings können auch mehrere Klassen gleichzeitig zutreffen, in diesem Fall müssen diese Klassen in der JSON Antwort zusätzlich angegeben werden.\n\n"
    const entities_prompt = "Die möglichen Entitäten die extrahiert werden können sind und jeweils ein Beispiel:\n"
    const entitiesString = Object.entries(this.entities_descriptions)
                                .map(([key, value]) => `Entity "${key}": ${value}`)
                                .join('\n')
    const entities_prompt_suffix = "\nWichtig: Es gibt KEINE anderen Entitäten die Möglich sind, auschließlich die zuvor genannten Entitäten können extrahiert werden!\n"
    const json_class_options = Object.keys(this.class_descriptions).map((key) => `"${key}"`).join("|")
    const json_entity_options = Object.keys(this.entities_descriptions).map((key) => `"${key}"`).join("|")
    let json_format_prompt = "Das JSON Format in dem IMMER geantwortet werden muss sieht schematisch folgendermaßen aus:\n```JSON\n{\n\t\"Main_Class\": <Klasse>,\n\t\"Additional_Classes\": [\n\t\t{\n\t\t\t\"Klasse\": <Klasse>,\n\t\t\t\"Relevanz\": <0-10>,\n\t\t\t\"Confidence\": <0.0-1.0>\n\t\t},\n\t\t{...}\n\t],\n\t\"Entities\":[\n\t\t{\n\t\t\t\"Type\": <Entity Type/Name>,\n\t\t\t\"Content\": <Entity Content>\n\t\t},\n\t\t{...}\n\t]\n}\n``` Achte darauf, dass das JSON korrekt formatiert ist. Bspw, dass der letzte Eintrag eines Dictionaries kein zusätzliches Komma hat. \n\n"
    json_format_prompt = json_format_prompt.replace(/<Klasse>/g, "<" + json_class_options + ">");
    json_format_prompt = json_format_prompt.replace(/<Entity Type\/Name>/g, "<" + json_entity_options + ">");
    const email_text_prompt = "E-Mail:\n" + "\"" + this.inputText +  "\""
    const prompt_prefix = system_prompt + classes_prompt + descriptionsString + classes_prompt_suffix + "\n\n" + entities_prompt + entitiesString + entities_prompt_suffix+ "\n\n" + json_format_prompt
    const prompt_suffix = "\n\nJSON_RESPONSE:\n```JSON"
    const prompt = prompt_prefix +  prompt_suffix
    //console.log("prompt: ", prompt);
    try {
      const response = await this.fetchFromBackend({ "system_prompt": prompt, "user_prompt": email_text_prompt});
      //console.log("response: ", response);
      //onsole.log("response_type: ", typeof response);
      // parse the response which is string into a JSON object
      const response_json = JSON.parse(response) as LLMResponseJson;
      if (response !== undefined) {

        // translate response_json to match TextPrediction
        const nebenanliegenClasses: Record<string, number> = response_json.Additional_Classes.reduce((acc, curr) => {
          acc[curr.Klasse] = curr.Confidence;
          return acc;
        }, {} as Record<string, number>);

        this.result = {
            class: response_json.Main_Class,
            entities: response_json.Entities.map(entity => ({
                name: entity.Type,
                value: entity.Content,
                tokenPosition: 0, // you might want to replace this with actual tokenPosition
                annotationColor: '' // likewise, replace this with actual annotationColor if any
            })),
            tokens: [], // I'm not sure where you get the tokens from, so I left it as an empty array
            multi_classes: {[response_json.Main_Class]: 1.0,  ...nebenanliegenClasses,},
            single_classes: {[response_json.Main_Class]: 1.0} // I'm not sure where you get the single_classes from, so I left it as an empty object
        };

        console.log("results: ", this.result);
      } else {
        console.log('Response text is undefined');
      }
      console.log("result: ", this.result);
      this.preprocessProbabilities();
      this.renderEntityAnnotations();
      this.status = TextStatus.Done;

    } catch (err) {
      console.error(err);
      this.status = TextStatus.Error;
    }
  }

  async fetchFromBackend(data: any): Promise<any>{
    
    const response = await fetch("https://europe-west3-ace-e-world.cloudfunctions.net/simple-eworld-2024", {
      method: 'POST', // or 'POST'
      headers: {
        'Content-Type': 'application/json',
        // 'Authorization': `Bearer ${accessToken.token}`
      },
      body: JSON.stringify(data),
    });

    return await response.json();
    
  }

  preprocessProbabilities(): void {
    if (!this.result || !this.result.multi_classes || !this.result.single_classes) {
      return;
    }

    // normalize floating number to integer between 0 and 100
    for (const [classname, prob] of Object.entries(this.result.multi_classes)) {
      const probNorm = Math.trunc(prob * 100);
      this.result.multi_classes[classname] = probNorm;
    }

    for (const [classname, prob] of Object.entries(this.result.single_classes)) {
      const probNorm = Math.trunc(prob * 100);
      this.result.single_classes[classname] = probNorm;
    }
  }

  renderEntityAnnotations(): void {
    if (!this.result) {
      return;
    }

    // copy of array
    // const renderedTokens = [...this.result.tokens];

    // annotate specific tokens which are entities
    for (const entity of this.result.entities) {
      // const origText = renderedTokens[entity.tokenPosition];
      // pick color for annotation in text and in resultbox
      const cssClass = this.chooseAnnotationColor();
      // set class in entity object
      entity.annotationColor = cssClass;

      // // overwrite token in text with html annotation
      // renderedTokens[
      //   entity.tokenPosition
      // ] = `<span class="${cssClass} has-text-white-bis">${origText}</span>`;

      // set annotationColor in entity object
      entity.annotationColor = cssClass;
    }

    // replace renderedInput with concatenated tokens
    this.renderedInput = this.inputText;
  }

  /**
   * Cycle through background colors to use as annotation background
   * Keeps track of used by an index
   */
  chooseAnnotationColor(): string {
    // choose color / css class by current color index
    const colorValues = Object.values(BACKGROUND_COLORS);
    const color = colorValues[this.colorIdx];

    // update color index
    this.colorIdx = (this.colorIdx + 1) % colorValues.length;
    return color;
  }

  sendFeedback(sentiment: string): void {
    // TODO do stuff depending on sentiment
    this.showFeedbackMsg = true;
  }

  closeMsg(): void {
    this.showFeedbackMsg = false;
  }
}
