// use backend proxy from vue.config.js for development
console.log("OPENAI_API_KEY", process.env.OPENAI_API_KEY);
export const BACKEND_URL =
  process.env.NODE_ENV === "production" ? process.env.VUE_APP_BACKEND_URL : "api/";

// mapping for css classes from 'main.scss'
export const BACKGROUND_COLORS = {
  teal: "background-teal",
  lightPurple: "background-light-purple",
  darkPurple: "background-dark-purple",
  mustardYellow: "background-mustard-yellow",
  orange: "background-orange",
  lightBlue: "background-light-blue",
  darkBlue: "background-dark-blue",
};
