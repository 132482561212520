
import { Vue, Options } from "vue-class-component";
import { Entity } from "@/types";

@Options({
  name: "entity-card",
  props: {
    entity: Object,
  },
})
export default class EntityCard extends Vue {
  entity!: Entity;
}
