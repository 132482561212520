import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a03720fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "entity-card" }
const _hoisted_2 = { class: "is-size-5 is-centered" }
const _hoisted_3 = { class: "entity-body" }
const _hoisted_4 = { class: "has-text-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", {
      class: _normalizeClass(["entity-header", [
        'background-color',
        _ctx.entity.annotationColor !== undefined ? _ctx.entity.annotationColor : 'grey',
      ]])
    }, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.entity.name), 1)
    ], 2),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("p", null, [
        _createTextVNode(" Text: "),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.entity.value), 1)
      ])
    ])
  ]))
}