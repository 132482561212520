
import { Vue, Options } from "vue-class-component";
import EntityCard from "@/components/EntityCard.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import { TextStatus, TextPrediction } from "@/types";

@Options({
  name: "result-box",
  components: { EntityCard, ProgressBar },
  props: {
    status: String,
    textPrediction: Object,
  },
})
export default class ResultBox extends Vue {
  status!: string;
  TextStatus = TextStatus; // needed to use enum in html template
  textPrediction!: TextPrediction;

  get predictedClasses(): [string, number][] {
    // sort descending by value
    console.log(this.textPrediction.single_classes);
    console.log(this.textPrediction.multi_classes);
    // const sortedClasses = Object.entries(this.textPrediction.single_classes)
    const sortedClasses = Object.entries(this.textPrediction.multi_classes)
      .sort(([, a], [, b]) => a - b)
      .reverse();
    return sortedClasses;
  }
}
