
import { Options, Vue } from "vue-class-component";
import HeaderBar from "@/components/HeaderBar.vue";
import ControlCenter from "@/components/ControlCenter.vue";
import BottomBar from "@/components/BottomBar.vue";
import InfoText from "@/components/InfoText.vue";
import ContactBox from "@/components/ContactBox.vue";

@Options({
  components: {
    HeaderBar,
    ControlCenter,
    InfoText,
    BottomBar,
    ContactBox,
  },
})
export default class Home extends Vue {}
