
import { Options, Vue } from "vue-class-component";

@Options({
  name: "drop-select",
})
export default class DropSelect extends Vue {
  selectKey = "";
  selectStatus = "";

  // adjustkey = "";

  selectChange(event: any): any {
    this.selectStatus = event.target.value;
    console.log("Change in Selection. current value: ", this.selectStatus);
  }

  setText1(selectStatus: string): void {
    const exampleNumber = 0;
    console.log("Event in Drop Select, status: ", selectStatus);
    this.$emit("set-example-text", { transferStatus: selectStatus, exNumber: exampleNumber });
  }

  setText2(selectStatus: string): void {
    const exampleNumber = 1;
    console.log("Event in Drop Select, status: ", selectStatus);
    this.$emit("set-example-text", { transferStatus: selectStatus, exNumber: exampleNumber });
  }

  setText3(selectStatus: string): void {
    const exampleNumber = 2;
    console.log("Event in Drop Select, status: ", selectStatus);
    this.$emit("set-example-text", { transferStatus: selectStatus, exNumber: exampleNumber });
  }
}
