import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
require("@/assets/main.scss"); // import bulma
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPaperPlane,
  faThumbsDown,
  faThumbsUp,
  faPen,
  faDatabase,
  faTachometerAlt,
  faInfoCircle,
  faTag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// add fontawesome library
library.add(
  faPaperPlane as any,
  faThumbsDown as any,
  faThumbsUp as any,
  faPen as any,
  faPen as any,
  faDatabase as any,
  faPen as any,
  faTachometerAlt as any,
  faInfoCircle as any,
  faTag as any
);

createApp(App).use(router).component("font-awesome-icon", FontAwesomeIcon).mount("#app");
