
import { Vue, Options } from "vue-class-component";

@Options({
  name: "progress-bar",
  props: {
    value: Number,
  },
})
export default class ProgressBar extends Vue {
  value!: number;
}
