
import { defineComponent, ref, reactive, watch, PropType } from 'vue';

export default defineComponent({
  name: 'simple-dialog',
  props: {
    visible: Boolean,
    descriptions: Object as PropType<Record<string, string>>,
  },
  emits: ['update:visible', 'save'],
  setup(props, { emit }) {
    const localDescriptions = ref({ ...props.descriptions });
    const visibilityStates = reactive<Record<string, boolean>>({});
    const newDescriptionName = ref(''); // For storing the input name

    // Initialize visibility states
    for (const key in props.descriptions) {
      visibilityStates[key] = false; // Initially all invisible
    }

    watch(() => props.descriptions, (newVal) => {
      localDescriptions.value = { ...newVal };
      // Update visibility states for new descriptions
      for (const key in newVal) {
        if (visibilityStates[key] === undefined) {
          visibilityStates[key] = true;
        }
      }
    });

    function addDescription(name: string) {
      if (name.trim() === '') {
        alert('Bitte geben Sie einen gültigen Namen ein.'); // Or handle this case more gracefully
        return;
      }
      if (Object.keys(localDescriptions.value).includes(name)) {
        alert('Ein Eintrag mit diesem Namen existiert bereits.'); // Or handle this case more gracefully
        return;
      }
      localDescriptions.value[name] = '';
      visibilityStates[name] = true; // Make new textarea visible by default
      newDescriptionName.value = ''; // Reset input field
    }

    function deleteDescription(key: string) {
      delete localDescriptions.value[key]; // Directly delete the key from the object
      delete visibilityStates[key]; // Delete the visibility state for the key
    }

    function toggleVisibility(key: string) {
      visibilityStates[key] = !visibilityStates[key];
    }

    function isVisible(key: string): boolean {
      return visibilityStates[key];
    }

    function close() {
      emit('update:visible', false);
    }

    function saveChanges() {
      emit('save', localDescriptions.value);
      close();
    }

    return { localDescriptions, newDescriptionName, close, saveChanges, addDescription, deleteDescription, toggleVisibility, isVisible };
  },
});
